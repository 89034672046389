import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const ToGoFurther = styled.div`
  padding: ${g(2)};
  border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};
  border-radius: ${g(1)};
`

export const EmojiContainer = styled.span`
  margin-right: ${g(1)};
`

export const Title = styled.span`
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  font-weight: 600;
`
