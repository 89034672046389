import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as S from './styles'

const ToGoFurther = ({ href, title }) => {
  return (
    <S.ToGoFurther>
      <S.EmojiContainer>
        <Emoji>:track_next:</Emoji>
      </S.EmojiContainer>
      Pour aller plus loin :{' '}
      <Link href={href}>
        <S.Title>{title}</S.Title>
      </Link>
    </S.ToGoFurther>
  )
}

ToGoFurther.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ToGoFurther
