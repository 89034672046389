import ToGoFurtherComponent from '@pretto/bricks/website/shared/components/ToGoFurther'

import * as S from '../styles'

const ToGoFurther = props => (
  <S.Block>
    <ToGoFurtherComponent {...props} />
  </S.Block>
)

export default ToGoFurther
